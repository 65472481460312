import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface CardListProps {
  title: string;
  imgUrlList: { label: string; imgUrl: string }[];
}

const Container = styled.div`
  background-color: #f6f4ef;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4rem 1rem;
  margin-top: 2rem;
  @media ${tablet} {
    padding: 2rem 1rem;
    margin-top: 0rem;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 2.25rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
    font-size: 20px;
  }
`;

const ItemLabel = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 8px;
  max-width: 116px;
`;

const GridContainer = styled.div<{ elementCount: number }>`
  display: grid;
  grid-template-columns: ${({ elementCount }) =>
    `repeat(${elementCount / 2}, 1fr)`};
  gap: 24px;
  justify-items: center;
  width: 744px;
  @media ${tablet} {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 12px;
    max-width: 692px;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 17px 24px;
  border-radius: 16px;
  height: 146px;
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    height: 142px;
    max-width: 164px;
    width: 100%;
  }
`;

const Image = styled(DynamicImage)`
  height: 70px;
  width: 92px;
`;

const Label = styled(Text)`
  margin-top: -2rem;
  margin-bottom: 2rem;
  @media ${tablet} {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const CardList: FC<CardListProps> = ({ title, imgUrlList, label }) => {
  const elementCount = imgUrlList.length;
  const renderCards = () =>
    imgUrlList.map(({ label, imgUrl }, index) => (
      <Card key={index}>
        <Image src={imgUrl} alt="" />
        <ItemLabel>{label}</ItemLabel>
      </Card>
    ));
  return (
    <Container>
      <InnerContainer>
        <Title>{title}</Title>
        {label ? <Label>{label}</Label> : null}
        <GridContainer elementCount={elementCount}>
          {renderCards()}
        </GridContainer>
      </InnerContainer>
    </Container>
  );
};

export default CardList;
