import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import Paw from 'assets/icons/upgrade/paw.svg';
import Bone from 'assets/icons/upgrade/bone.svg';
import { mobile, tablet, useQuery } from 'styles/breakpoints';

interface DogPawListProps {
  image: string;
  title: string;
  label: string;
  list: { title: string; label: string }[];
  variant?: 'paw' | 'bone';
  bgColor?: string;
  altTitle?: string;
  highlightColor?: string;
  imageMargin?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;
  display: flex;
  flex-direction: row;
  padding: 4rem 1rem;
  @media ${tablet} {
    flex-direction: column-reverse;
    padding: 0 1rem 2rem 1rem;
  }
`;

const Title = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  span {
    color: #38724f;
  }
  margin-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
  @media ${mobile} {
    text-align: left;
  }
`;

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 16px;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  @media ${tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const RightContainer = styled.div`
  flex: 1;
`;

const ImageStyled = styled(DynamicImage)<{ imageMargin: string }>`
  margin: ${({ imageMargin }) => imageMargin};
`;

const ListContainer = styled.div``;

const ListItem = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
`;
const ListTextContainer = styled.div`
  margin-left: 0.75rem;
`;

const ListTitle = styled(Text)<{ color: string }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ color }) => color};
  margin-bottom: 0.25rem;
  @media ${tablet} {
    font-size: 16px;
  }
`;

const ListLabel = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #28293d;
  max-width: 370px;
`;

const AltTitle = styled(Title)<{ altTitleColor?: string }>`
  color: ${({ altTitleColor }) => altTitleColor};
  text-align: left;
  margin-bottom: 1.5rem;
  margin-top: 0;
  @media ${tablet} {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

const DogPawList: FC<DogPawListProps> = ({
  image,
  label,
  list,
  title,
  variant = 'paw',
  bgColor = '#f6f4ef',
  altTitle,
  highlightColor = '#38724f',
  imageMargin = '0',
}) => {
  const { isTablet } = useQuery();

  const renderItem = () =>
    list.map(({ label, title }, index) => (
      <ListItem key={index}>
        {variant === 'bone' ? (
          <Bone fill={highlightColor} />
        ) : (
          <Paw fill={highlightColor} />
        )}
        <ListTextContainer>
          <ListTitle color={highlightColor}>{title}</ListTitle>
          <ListLabel>{label}</ListLabel>
        </ListTextContainer>
      </ListItem>
    ));
  return (
    <Container bgColor={bgColor}>
      <InnerContainer>
        <LeftContainer>
          {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
          {label ? <Label dangerouslySetInnerHTML={{ __html: label }} /> : null}
          {!isTablet && altTitle ? (
            <AltTitle
              dangerouslySetInnerHTML={{ __html: altTitle }}
              altTitleColor={highlightColor}
            />
          ) : null}
          <ListContainer>{renderItem()}</ListContainer>
        </LeftContainer>
        <RightContainer>
          <ImageStyled src={image} alt="" imageMargin={imageMargin} />
        </RightContainer>
        {isTablet && altTitle ? (
          <AltTitle
            dangerouslySetInnerHTML={{ __html: altTitle }}
            altTitleColor={highlightColor}
          />
        ) : null}
      </InnerContainer>
    </Container>
  );
};

export default DogPawList;
